<template>
  <v-app>
    <verses-view :entry="$route.params.entry" />
    <salvation />
    <app-footer />
  </v-app>
</template>

<script>
// Extensions
import View from "@/views/View";
import { upperFirst } from "lodash";

export default {
  name: "HelpEntry",

  metaInfo() {
    return {
      title: upperFirst(this.$route.params.entry.replace("-", " & "))
    };
  },

  components: {
    AppFooter: () => import("@/views/sections/AppFooter"),
    Salvation: () => import("@/views/sections/Salvation"),
    VersesView: () => import("@/components/Verses")
  },
  extends: View,

  props: {
    id: {
      type: String,
      default: "help-entry"
    }
  }
};
</script>
